import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Head from './components/Head.tsx';
import Body from './components/Body.jsx';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Head />
        <Routes>
          <Route path="/guess/:Idguess" element={<Body />} />
          <Route path="/" element={<Body />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
