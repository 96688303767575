import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import './css/modal.css';
import './css/confimarionButtom.css';  
import { Calendar } from 'primereact/calendar';


const OurHistory: React.FC = () => {
  const { Idguess } = useParams<{ Idguess: string }>();
  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [tiketsSize, setTiketsSize] = useState<number>(0);
  const [guess, setGuess] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ticketsNoConfirmed, setTicketsNoConfirmed] = useState<boolean>(true);
  const [tickets, setTickets] = useState<Array<{ isConfirmed: boolean }>>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTicketIndex, setSelectedTicketIndex] = useState<number | null>(null);
  const [birthYear, setBirthYear] = useState<string>('');

  const minDate = new Date(1940, 0, 1);
  const maxDate = new Date(2010, 11, 31);

  useEffect(() => {
    const fetchName = async () => {
      try {
        const response = await fetch(`https://api.losneneswedding.com/guess/${Idguess}`);

        var data = await response.json();
        if(!data){
           data = {"body":{
            "tickets": [
            ], 
            "ticketsCount":0,          
          }};
        }

        setName(data.body.name);
        setTiketsSize(data.body.ticketsCount);
        setGuess(data.body.guess);
        setComment(data.body.comments)
        setTickets(data.body.tickets);
        
      } catch (error) {
        console.error('Error fetching the name:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchName();
  }, [Idguess]);

  const handleConfirm = (index: number, isConfirmed: boolean) => {
    setError('');
    setSelectedTicketIndex(index);  
    if(!isConfirmed)
      setModalIsOpen(true);
  };

  const handleSubmit = async (event) => {
    setModalIsOpen(false);
    event.preventDefault();
    console.log(birthYear)
    console.log(selectedTicketIndex)
    if (birthYear && selectedTicketIndex !== null) {
      const dateObject: Date = new Date(birthYear);
      console.log("Año de nacimiento:", dateObject.getFullYear()); // Aquí puedes manejar el valor del año de nacimiento como lo necesites

      // Realizar la solicitud POST a la URL proporcionada
      try {
        const response = await fetch(`https://api.losneneswedding.com/guess/${Idguess}/confirmTicket`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ticket: {
              birthYear:  dateObject.getFullYear(),
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Response from server:', responseData);

        const updatedTickets = [...tickets];
        updatedTickets[selectedTicketIndex] = { ...updatedTickets[selectedTicketIndex], isConfirmed: true };
        setTickets(updatedTickets);
       

      } catch (error) {
        setModalIsOpen(false);
        document.body.classList.remove('modal-open'); 
        console.error('Error during the fetch request:', error);
        const updatedTickets = [...tickets];
        updatedTickets[selectedTicketIndex] = { ...updatedTickets[selectedTicketIndex], isConfirmed: true };
        setTickets(updatedTickets);
      }
    }else{
      setError("Pon un año valido please");
    }
    
    document.body.classList.remove('modal-open'); 
  };

  const handleBirthYearChange = (event) => {
    setBirthYear(event.target.value);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
     <div className="elementor-element elementor-element-c78117b e-flex e-con-boxed e-con e-parent" data-id="c78117b" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}" data-core-v316-plus="true">
                      <div className="e-con-inner">
                        <div className="elementor-element elementor-element-359cf14 e-con-full e-flex e-con e-child" data-id="359cf14" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                          <div className="elementor-element elementor-element-8189e0e elementor-widget elementor-widget-image" data-id="8189e0e" data-element_type="widget" data-widget_type="image.default">
                            <div className="elementor-widget-container">
                              <img decoding="async" width="45" height="70" src="https://websitedemos.net/wedding-02/wp-content/uploads/sites/48/2020/06/flower-icon-01.png" className="attachment-full size-full wp-image-698" />
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-1150d74 elementor-widget elementor-widget-heading" data-id="1150d74" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">{name}</h2>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-43ad37a elementor-widget elementor-widget-text-editor" data-id="43ad37a" data-element_type="widget" data-widget_type="text-editor.default">
                            {tiketsSize == 1 ? (
                              <div className="elementor-widget-container">Será un honor para nosotros que puedas acompañarnos en este día tan especial para nosotros</div>
                            ) : (
                              guess ? (
                                <div className="elementor-widget-container">Será un honor que tú {guess} puedan acompañarnos en este dia tan especial para nosotros</div>
                              ) : (
                                <div className="elementor-widget-container">Será un honor que tú y tu invitado puedan acompañarnos en este dia tan especial para nosotros</div>
                              )    
                            )}
                          </div>
                          {error  ? (
                              <div className="elementor-element elementor-element-43ad37a elementor-widget elementor-widget-text-editor" data-id="43ad37a" data-element_type="widget" data-widget_type="text-editor.default">
                              <div className="elementor-widget-container">
                                <div className="error-message">
                                  {error}
                                </div>
                              </div>
                            </div>
                          ): (
                            <div></div>

                          )}
                          
                          {tickets && tickets.length > 0 ? (
                            <div className="elementor-element elementor-element-43ad37a elementor-widget elementor-widget-text-editor" data-id="43ad37a" data-element_type="widget" data-widget_type="text-editor.default">
                              {tickets.map((ticket, index) => (
                                <div key={index} className="elementor-element e-con-full e-flex e-con e-child">
                                  <button onClick={() => handleConfirm(index, ticket.isConfirmed)} className={`confirmation-button ${ticket.isConfirmed ? 'confirmed-button' : ''}`}>
                                    {ticket.isConfirmed ? 'Gracias por Confirmar' : 'Confirmar Boleto'}
                                  </button>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div></div>
                          )}

                          <div className="elementor-element elementor-element-43ad37a elementor-widget elementor-widget-text-editor" data-id="43ad37a" data-element_type="widget" data-widget_type="text-editor.default">
                            {comment? (
                              <div className="elementor-element elementor-element-43ad37a elementor-widget elementor-widget-text-editor" data-id="43ad37a" data-element_type="widget" data-widget_type="text-editor.default">
                              <div className="elementor-widget-container">{comment}</div>
                            </div>
                            
                            ) : (     
                                                       
                              <div className="elementor-element elementor-element-43ad37a elementor-widget elementor-widget-text-editor" data-id="43ad37a" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container">Es por eso que solicitamos que leas la siguiente información y confirmes tu asistencia</div>
                              </div>
                                                
                            )}
                          </div>

                          

                              <Modal
                                  isOpen={modalIsOpen}
                                  onRequestClose={() => setModalIsOpen(false)}
                                  contentLabel="Formulario de Año de Nacimiento"
                                  className="modal-modal"
                                  overlayClassName="overlay-modal">
                                  <h2>Ingrese su Año de Nacimiento</h2>
                                  <form onSubmit={handleSubmit} className="year-form">
                                    <label>
                                    <Calendar 
                                        name="birthYear" 
                                        required 
                                        onChange={handleBirthYearChange} 
                                        view="year" 
                                        dateFormat="yy" 
                                        placeholder="Seleccione un año"
                                        maxDate={new Date(2010, 0, 1)}
                                        showIcon 
                                        yearRange='12'
                                        inputStyle={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' , background: 'white'}}
                                        panelStyle={{ zIndex: 1100 }} 
                                      />
                                    </label>
                                    <button type="submit" className="submit-button">Enviar</button>
                                    <button type="button" onClick={() => setModalIsOpen(false)} className="cancel-button">Cancelar</button>
                                  </form>
                                </Modal>
                          <div className="elementor-element elementor-element-f3995b4 e-con-full e-flex e-con e-child" data-id="f3995b4" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;,&quot;content_width&quot;:&quot;full&quot;}">
                            <div className="elementor-element elementor-element-6f44f5b e-con-full e-flex e-con e-child" data-id="6f44f5b" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                              <div className="elementor-element elementor-element-7cc04fc elementor-widget elementor-widget-heading" data-id="7cc04fc" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container">
                                  <h3 className="elementor-heading-title elementor-size-default">Itzel Zárate</h3>
                                </div>
                              </div>
                              <div className="elementor-element elementor-element-52ba3c8 elementor-widget elementor-widget-text-editor" data-id="52ba3c8" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container"> Itzel, una luz llena de emociones y sueños. Su pasión por la vida y su lealtad a la familia y a sus ideales es inquebrantable. Dedicada al amor en todas sus formas, aporta una calidez inigualable a la vida de quienes la rodean. Ambos, saben que son el amor de sus vidas y están seguros de que este es solo el comienzo de una hermosa y gran vida juntos. </div>
                              </div>
                              <div className="elementor-element elementor-element-9c91d96 elementor-shape-circle e-grid-align-tablet-left e-grid-align-right e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons" data-id="9c91d96" data-element_type="widget" data-widget_type="social-icons.default">
                                <div className="elementor-widget-container">
                                  <div className="elementor-social-icons-wrapper elementor-grid">
                                    
                                    <span className="elementor-grid-item">
                                      <a className="elementor-icon elementor-social-icon elementor-social-icon-whatsapp elementor-repeater-item-km5tkzz" href="https://api.whatsapp.com/send/?phone=%2B525539265285" target="_blank">
                                        <span className="elementor-screen-only">WhatsApp</span>
                                        <i className="fab fa-whatsapp"></i>
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="elementor-element elementor-element-9bd7c0c e-con-full e-flex e-con e-child" data-id="9bd7c0c" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;,&quot;content_width&quot;:&quot;full&quot;}">
                              <div className="elementor-element elementor-element-68ead85 elementor-widget elementor-widget-image" data-id="68ead85" data-element_type="widget" data-widget_type="image.default">
                                <div className="elementor-widget-container">
                                <img
                                    {...{
                                        fetchpriority: "high",
                                        decoding: "async",
                                        width: "640",
                                        height: "640",
                                        src: "https://weddingmanuitz.s3.amazonaws.com/OurHistory.jpg",
                                        className: "attachment-large size-large wp-image-795",
                                        alt: "About couple image",
                                        srcSet: "https://weddingmanuitz.s3.amazonaws.com/OurHistory.jpg 640w, https://weddingmanuitz.s3.amazonaws.com/OurHistory300x300.jpeg 300w,https://weddingmanuitz.s3.amazonaws.com/OurHistory150x150.jpeg 150w, https://weddingmanuitz.s3.amazonaws.com/OurHistory400x400.jpeg 400w",
                                        sizes: "(max-width: 640px) 100vw, 640px",
                                    }}
                                    />

                                </div>
                              </div>
                            </div>
                            <div className="elementor-element elementor-element-b116da8 e-con-full e-flex e-con e-child" data-id="b116da8" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                              <div className="elementor-element elementor-element-6aea876 elementor-widget elementor-widget-heading" data-id="6aea876" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container">
                                  <h3 className="elementor-heading-title elementor-size-default">Manuel Ambriz​</h3>
                                </div>
                              </div>
                              <div className="elementor-element elementor-element-0c1d33a elementor-widget elementor-widget-text-editor" data-id="0c1d33a" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container">
                                  <p>Manuel, un soñador incansable, cuya pasión por la vida es contagiosa. Su inteligencia y dedicación lo definen, pero es su nobleza y su corazón invaluable lo que realmente lo hace único. Sus locuras y ocurrencias siempre sacan las más sinceras risas de quienes lo rodean. Ama con todo su ser, pero sobre todas las cosas su mayor deseo es ver feliz a su amada y vivir toda la vida a su lado.</p>
                                </div>
                              </div>
                              <div className="elementor-element elementor-element-c6a9e74 elementor-shape-circle e-grid-align-tablet-right e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons" data-id="c6a9e74" data-element_type="widget" data-widget_type="social-icons.default">
                                <div className="elementor-widget-container">
                                  <div className="elementor-social-icons-wrapper elementor-grid">
                           
                                    <span className="elementor-grid-item">
                                      <a className="elementor-icon elementor-social-icon elementor-social-icon-whatsapp elementor-repeater-item-km5tkzz" href="https://api.whatsapp.com/send/?phone=%2B525549151657" target="_blank">
                                        <span className="elementor-screen-only">WhatsApp</span>
                                        <i className="fab fa-whatsapp"></i>
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  );
};

export default OurHistory;
