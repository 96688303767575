// src/components/Head.jsx
import React from 'react';
import { Helmet } from 'react-helmet';
import './css/astra-google-fonts.css';
import './css/astra-theme-css-inline.css';
import './css/global-styles-inline.css';
import './css/google-fonts-1.css';
import './css/wp-emoji-styles-inline.css';


import './css/external/elementor-icons.css';
import './css/external/all.css';
import './css/external/blocks.css';
import './css/external/brands.css';
import './css/external/fontawesome.css';
import './css/external/frontend.css';
import './css/external/post-2.css';
import './css/external/post-594.css';
import './css/external/regular.css';
import './css/external/solid.css';
import './css/external/style-flex.min.css';
import './css/external/style.css';
import './css/external/swiper.css';
import './css/external/v4-shims.css';
import './css/external/wpforms-base.min.css';

const Head = () => {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="profile" href="https://gmpg.org/xfn/11" />

      {/* Primer script */}
      <script type="text/javascript">
        {`
          var isInIFrame = (window.self !== window.top) ? true : false;
          if (!isInIFrame) {
            var jsData = ["showcase-cta", "https:\/\/websitedemos.net\/wedding-02\/wp-content\/plugins\/astra-sites-server\/admin\/showcase-cta\/switcher\/dist\/style-main.css?ver=308d53dc52d1fcf8ca1e"];
            var style = document.createElement('link');
            style.setAttribute('id', jsData[0]);
            style.setAttribute('rel', 'stylesheet');
            style.setAttribute('type', 'text/css');
            style.setAttribute('media', 'all');
            style.setAttribute('href', jsData[1]);
            document.head.appendChild(style);
          }
        `}
      </script>

      {/* Segundo script */}
      <script type="text/javascript">
        {`
          var isInIFrame = (window.self !== window.top) ? true : false;
          if (!isInIFrame) {
            var jsData = ["showcase-cta-google-fonts", "\/\/fonts.googleapis.com\/css?family=DM%20Sans%3A400%2C500%2C700&subset=latin%2Clatin-ext"];
            var style = document.createElement('link');
            style.setAttribute('id', jsData[0]);
            style.setAttribute('rel', 'stylesheet');
            style.setAttribute('type', 'text/css');
            style.setAttribute('media', 'all');
            style.setAttribute('href', jsData[1]);
            document.head.appendChild(style);
          }
        `}
      </script>

      <meta name="robots" content="noindex, nofollow" />
      <title>Manuel & Itzel</title>
      <link rel="dns-prefetch" href="https://fonts.googleapis.com/" />
      <link rel="alternate" type="application/rss+xml" title="Wedding Invitation &raquo; Feed" href="https://websitedemos.net/wedding-02/feed/" />
      <link rel="alternate" type="application/rss+xml" title="Wedding Invitation &raquo; Comments Feed" href="https://websitedemos.net/wedding-02/comments/feed/" />
      
      <link rel="preconnect" href="https://fonts.gstatic.com/" />
      <script src="https://websitedemos.net/wedding-02/wp-includes/js/dist/vendor/wp-polyfill-inert.js?ver=3.1.2" id="wp-polyfill-inert-js"></script>
      <script src="https://websitedemos.net/wedding-02/wp-includes/js/dist/vendor/regenerator-runtime.js?ver=0.14.0" id="regenerator-runtime-js"></script>
      <script src="https://websitedemos.net/wedding-02/wp-includes/js/dist/vendor/wp-polyfill.js?ver=3.15.0" id="wp-polyfill-js"></script>
      <script src="https://websitedemos.net/wedding-02/wp-includes/js/dist/development/react-refresh-runtime.js?ver=79d08edf9bea9ade42e6" id="wp-react-refresh-runtime-js"></script>
      <script src="https://websitedemos.net/wedding-02/wp-includes/js/dist/development/react-refresh-entry.js?ver=794dd7047e2302828128" id="wp-react-refresh-entry-js"></script>
      <script src="https://websitedemos.net/wedding-02/wp-includes/js/dist/dom-ready.js?ver=ae5bd6ca23f589f2dac7" id="wp-dom-ready-js"></script>
      <script src="https://websitedemos.net/wedding-02/wp-includes/js/dist/url.js?ver=2eb43eef60790a73edaf" id="wp-url-js"></script>

      <script src="https://websitedemos.net/wedding-02/wp-content/plugins/elementor/assets/lib/font-awesome/js/v4-shims.js?ver=3.17.3" id="font-awesome-4-shim-js"></script>
      <link rel="https://api.w.org/" href="https://websitedemos.net/wedding-02/wp-json/" />
      <link rel="alternate" type="application/json" href="https://websitedemos.net/wedding-02/wp-json/wp/v2/pages/2" />
      <link rel="EditURI" type="application/rsd+xml" title="RSD" href="https://websitedemos.net/wedding-02/xmlrpc.php?rsd" />
      <meta name="generator" content="WordPress 6.4.2" />
      <link rel="shortlink" href="index.html" />
      <link rel="alternate" type="application/json+oembed" href="https://websitedemos.net/wedding-02/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwebsitedemos.net%2Fwedding-02%2F" />
      <link rel="alternate" type="text/xml+oembed" href="https://websitedemos.net/wedding-02/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwebsitedemos.net%2Fwedding-02%2F&#038;format=xml" />

    </Helmet>
  );
};

export default Head;
