import React, { useState } from "react";;
import Modal from "react-modal";

const EventAttractions: React.FC = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    return (
        <div className="elementor-element elementor-element-3c66bb9 e-flex e-con-boxed e-con e-parent" data-id="3c66bb9" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}" data-core-v316-plus="true">
            <div className="e-con-inner">
                <div className="elementor-element elementor-element-bb8a992 e-con-full e-flex e-con e-child" data-id="bb8a992" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                    <div className="elementor-element elementor-element-3515f56 elementor-widget elementor-widget-image" data-id="3515f56" data-element_type="widget" data-widget_type="image.default">
                        <div className="elementor-widget-container">
                            <img decoding="async" width="45" height="70" src="https://websitedemos.net/wedding-02/wp-content/uploads/sites/48/2020/06/rose-icon-01.png" className="attachment-full size-full wp-image-697" alt="" />
                        </div>
                    </div>
                    <div className="elementor-element elementor-element-7cae59e elementor-widget elementor-widget-image-box" data-id="7cae59e" data-element_type="widget" data-widget_type="image-box.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                                <div className="elementor-image-box-content">
                                    <h3 className="elementor-image-box-title">Información del Evento</h3>
                                    <p className="elementor-image-box-description">Desde la elegancia de la ceremonia hasta la euforia de la celebración, te agradeceremos enormemente que consideres los siguientes punto:.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elementor-element elementor-element-e9bb754 e-con-full e-flex e-con e-child" data-id="e9bb754" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                        <div className="elementor-element elementor-element-ac07c27 e-con-full e-flex e-con e-child" data-id="ac07c27" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                            <div className="elementor-element elementor-element-8294192 elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="8294192" data-element_type="widget" data-widget_type="icon-box.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-icon-box-wrapper">
                                        <div className="elementor-icon-box-icon">
                                            <span className="elementor-icon elementor-animation-">
                                                <i aria-hidden="true" className="fas fa-hotel"></i>
                                            </span>
                                        </div>
                                        <div className="elementor-icon-box-content">
                                            <h5 className="elementor-icon-box-title">
                                                <span> Hospedaje </span>
                                            </h5>
                                            <p className="elementor-icon-box-description"> El evento será a 10 min del centro de Tepoztlan Morelos, aquí te dejamos algunas opciones:</p>
                                            
                                            <a href="#" onClick={openModal}>
                                                Ver opciones de hospedaje (PDF)
                                            </a>

                                            <Modal
                                                isOpen={modalIsOpen}
                                                onRequestClose={closeModal}
                                                contentLabel="PDF Viewer"
                                                style={{
                                                    content: {
                                                        width: '80%',
                                                        height: '80%',
                                                        margin: 'auto',
                                                        padding: '0',
                                                    },
                                                }}
                                            >
                                                <iframe
                                                    src="https://weddingmanuitz.s3.amazonaws.com/Listado+de+hoteles+cercanos+.pdf"
                                                    width="100%"
                                                    height="100%"
                                                    style={{ border: "none" }}
                                                    title="PDF Viewer"
                                                ></iframe>
                                               
                                            </Modal>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-fd5cb62 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="fd5cb62" data-element_type="widget" data-widget_type="divider.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-divider">
                                        <span className="elementor-divider-separator"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-fe371ad elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="fe371ad" data-element_type="widget" data-widget_type="icon-box.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-icon-box-wrapper">
                                        <div className="elementor-icon-box-icon">
                                            <span className="elementor-icon elementor-animation-">
                                                <i aria-hidden="true" className="fas fa-baby"></i>
                                            </span>
                                        </div>
                                        <div className="elementor-icon-box-content">
                                            <h5 className="elementor-icon-box-title">
                                                <span> Niños en casa</span>
                                            </h5>
                                            <p className="elementor-icon-box-description"> Queremos que disfruten y bailen sin parar, por eso la invitación es SOLO PARA ADULTOS. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-f7b3f0c elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="f7b3f0c" data-element_type="widget" data-widget_type="divider.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-divider">
                                        <span className="elementor-divider-separator"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-a68bf87 e-con-full e-flex e-con e-child" data-id="a68bf87" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                            <div className="elementor-element elementor-element-699ff87 elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="699ff87" data-element_type="widget" data-widget_type="icon-box.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-icon-box-wrapper">
                                        <div className="elementor-icon-box-icon">
                                            <span className="elementor-icon elementor-animation-">
                                                <i aria-hidden="true" className="fas fa-crown"></i>
                                            </span>
                                        </div>
                                        <div className="elementor-icon-box-content">
                                            <h5 className="elementor-icon-box-title">
                                                <span> Dress Code </span>
                                            </h5>
                                            <p className="elementor-icon-box-description"> Formal (Black Tie).<br></br>
                                                Siéntete libre de traer tus tenis favoritos.<br></br> 
                                                NO blanco.<br></br> 
                                                NO dorado. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-203940b elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="203940b" data-element_type="widget" data-widget_type="divider.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-divider">
                                        <span className="elementor-divider-separator"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-c78ae3e elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="c78ae3e" data-element_type="widget" data-widget_type="icon-box.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-icon-box-wrapper">
                                        <div className="elementor-icon-box-icon">
                                            <span className="elementor-icon elementor-animation-">
                                                <i aria-hidden="true" className="fas fa-cocktail"></i>
                                            </span>
                                        </div>
                                        <div className="elementor-icon-box-content">
                                            <h5 className="elementor-icon-box-title">
                                                <span> Fiesta </span>
                                            </h5>
                                            <p className="elementor-icon-box-description"> La boda oficial será el sábado y la pool party el domingo; acompáñanos en ambas. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-a2b2538 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="a2b2538" data-element_type="widget" data-widget_type="divider.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-divider">
                                        <span className="elementor-divider-separator"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-aa2bcd5 e-con-full e-flex e-con e-child" data-id="aa2bcd5" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                            <div className="elementor-element elementor-element-3514107 elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="3514107" data-element_type="widget" data-widget_type="icon-box.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-icon-box-wrapper">
                                        <div className="elementor-icon-box-icon">
                                            <span className="elementor-icon elementor-animation-">
                                                <i aria-hidden="true" className="fas fa-car-side"></i>
                                            </span>
                                        </div>
                                        <div className="elementor-icon-box-content">
                                            <h5 className="elementor-icon-box-title">
                                                <span> Estacionamiento </span>
                                            </h5>
                                            <p className="elementor-icon-box-description"> Habrá servicio de Valet Parking, pero la fiesta estará en una zona muy céntrica, por lo que te recomendamos usar taxi o transporte de aplicación. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-3299344 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="3299344" data-element_type="widget" data-widget_type="divider.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-divider">
                                        <span className="elementor-divider-separator"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-9155bf0 elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="9155bf0" data-element_type="widget" data-widget_type="icon-box.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-icon-box-wrapper">
                                        <div className="elementor-icon-box-icon">
                                            <span className="elementor-icon elementor-animation-">
                                                <i aria-hidden="true" className="fas fa-gift"></i>
                                            </span>
                                        </div>
                                        <div className="elementor-icon-box-content">
                                            <h5 className="elementor-icon-box-title">
                                                <span> Mesa de Regalos </span>
                                            </h5>
                                            <p className="elementor-icon-box-description">
                                                <a href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51419060" target="_blank" rel="noopener noreferrer">
                                                    Liverpool
                                                </a>
                                                <br></br>
                                                <a href="https://www.amazon.com.mx/wedding/registry/1CY30A0RJK7PX" target="_blank" rel="noopener noreferrer">
                                                    Amazon
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-31d3c77 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="31d3c77" data-element_type="widget" data-widget_type="divider.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-divider">
                                        <span className="elementor-divider-separator"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventAttractions;
