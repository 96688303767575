import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm("xvoegzjv");
  if (state.succeeded) {
      return <p>Gracias por tu confirmación, Te amamos </p>;
  }
  return (
    <form onSubmit={handleSubmit}
        id="wpforms-form-512"
        className="wpforms-validate wpforms-form"
        data-formid="512"
        method="post"
        encType="multipart/form-data"
        action="index.html"
        data-token="ce6339dbe36b12242a1b792a98719e28"
    >

        <div className="wpforms-field-container">
        <div id="wpforms-512-field_0-container" className="wpforms-field wpforms-field-name" data-field-id="0">
          <div className="elementor-element elementor-element-1150d74 elementor-widget elementor-widget-heading" data-id="1150d74" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">Esperamos tus comentarios</h2>
                            </div>
                          </div>
        </div>
        <div id="wpforms-512-field_3-container" className="wpforms-field wpforms-field-text" data-field-id="3">
            <label className="wpforms-field-label wpforms-label-hide" htmlFor="wpforms-512-field_3">Número celular</label>
            <input type="text" id="wpforms-512-field_3" className="wpforms-field-large" name="wpforms[fields][3]" placeholder="Número de Telefono" />
        </div>
        <div id="wpforms-512-field_2-container" className="wpforms-field wpforms-field-textarea" data-field-id="2">
            <label className="wpforms-field-label wpforms-label-hide" htmlFor="wpforms-512-field_2">
            Mensaje <span className="wpforms-required-label">*</span>
            </label>
            <textarea
            id="wpforms-512-field_2"
            className="wpforms-field-large wpforms-field-required"
            name="wpforms[fields][2]"
            placeholder="Dudas o comentarios"
            required
            ></textarea>
        </div>
        </div>
        <div className="wpforms-field wpforms-field-hp">
        <label htmlFor="wpforms-512-field-hp" className="wpforms-field-label">
            Comentarios
        </label>
        <input type="text" name="wpforms[hp]" id="wpforms-512-field-hp" className="wpforms-field-medium" />
        </div>
        <div className="wpforms-submit-container">
        <input type="hidden" name="wpforms[id]" value="512" />
        <input type="hidden" name="wpforms[author]" value="12" />
        <input type="hidden" name="wpforms[post_id]" value="2" />
        <button type="submit" disabled={state.submitting} name="wpforms[submit]" id="wpforms-submit-512" className="wpforms-submit" data-alt-text="Sending..." data-submit-text="Send" aria-live="assertive" value="wpforms-submit">
            Enviar
        </button>
        </div>
    </form>
  );
}
function App() {
  return (
    <ContactForm />
  );
}
export default App;