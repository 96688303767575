import React from 'react';

const Information: React.FC = () => {
  return (
    <div className="elementor-element elementor-element-9e7fef4 e-flex e-con-boxed e-con e-parent" data-id="9e7fef4" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;boxed&quot;}" data-core-v316-plus="true">
      <div className="e-con-inner">
        <div className="elementor-element elementor-element-c3aa727 e-con-full e-flex e-con e-child" data-id="c3aa727" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;full&quot;}">
          <div className="elementor-element elementor-element-d1831b1 elementor-widget elementor-widget-image" data-id="d1831b1" data-element_type="widget" data-widget_type="image.default">
            <div className="elementor-widget-container">
              <img decoding="async" width="45" height="70" src="https://websitedemos.net/wedding-02/wp-content/uploads/sites/48/2020/06/rose-icon-01.png" className="attachment-full size-full wp-image-697" alt="" />
            </div>
          </div>
          <div className="elementor-element elementor-element-705b94c elementor-widget elementor-widget-image-box" data-id="705b94c" data-element_type="widget" data-widget_type="image-box.default">
            <div className="elementor-widget-container">
              <div className="elementor-image-box-wrapper">
                <div className="elementor-image-box-content">
                  <h3 className="elementor-image-box-title">Fecha y Lugar</h3>
                  <p className="elementor-image-box-description">Nos encantará contar con tu presencia, y agradeceremos que consideres lo siguiente</p>
                </div>
              </div>
            </div>
          </div>
          <div className="elementor-element elementor-element-c509bac e-con-full e-flex e-con e-child" data-id="c509bac" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
            <div className="elementor-element elementor-element-45a937e e-con-full e-flex e-con e-child" data-id="45a937e" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;full&quot;}">
              <div className="elementor-element elementor-element-29a8175 elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="29a8175" data-element_type="widget" data-widget_type="icon-box.default">
                <div className="elementor-widget-container">
                  <div className="elementor-icon-box-wrapper">
                    <div className="elementor-icon-box-icon">
                      <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="far fa-clock"></i>
                      </span>
                    </div>
                    <div className="elementor-icon-box-content">
                      <h4 className="elementor-icon-box-title">
                        <span> Ceremonia </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-element elementor-element-77a8453 elementor-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="77a8453" data-element_type="widget" data-widget_type="icon-list.default">
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Sábado 28 de Diciembre de 2024</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">02:00 PM</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Texcal, de Tecmilco No. 7, Santo Domingo</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Tepoztlán, Mor.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-5f3ff9e e-con-full e-flex e-con e-child" data-id="5f3ff9e" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;full&quot;}">
              <div className="elementor-element elementor-element-9149cb4 elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="9149cb4" data-element_type="widget" data-widget_type="icon-box.default">
                <div className="elementor-widget-container">
                  <div className="elementor-icon-box-wrapper">
                    <div className="elementor-icon-box-icon">
                      <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-glass-martini-alt"></i>
                      </span>
                    </div>
                    <div className="elementor-icon-box-content">
                      <h4 className="elementor-icon-box-title">
                        <span> Fiesta </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-element elementor-element-72a6021 elementor-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="72a6021" data-element_type="widget" data-widget_type="icon-list.default">
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Sábado 28 de Diciembre de 2024</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">16:00 PM - 03.00 AM</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Texcal, de Tecmilco No. 7, Santo Domingo</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Tepoztlán, Mor.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-5f3ff9e e-con-full e-flex e-con e-child" data-id="5f3ff9e" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;full&quot;}">
              <div className="elementor-element elementor-element-9149cb4 elementor-view-stacked elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="9149cb4" data-element_type="widget" data-widget_type="icon-box.default">
                <div className="elementor-widget-container">
                  <div className="elementor-icon-box-wrapper">
                    <div className="elementor-icon-box-icon">
                      <span className="elementor-icon elementor-animation-">
                      <i aria-hidden="true" className="fas fa-swimming-pool"></i>
                      </span>
                    </div>
                    <div className="elementor-icon-box-content">
                      <h4 className="elementor-icon-box-title">
                        <span> Pool Party </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-element elementor-element-72a6021 elementor-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="72a6021" data-element_type="widget" data-widget_type="icon-list.default">
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Domingo 29 de Diciembre de 2024</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">10:00 AM - 04.00 PM</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Texcal, de Tecmilco No. 7, Santo Domingo</span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-text">Tepoztlán, Mor.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="elementor-element elementor-element-8252c23 elementor-widget elementor-widget-google_maps" data-id="8252c23" data-element_type="widget" data-widget_type="google_maps.default">
            <div className="elementor-widget-container">
              <div className="elementor-custom-embed">
                <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.1125686131236!2d-99.06656751440892!3d18.970635336348334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0cd4114b66fd%3A0x25a59a908e65d5fd!2sHotel%20Boutique%20Castillo%20de%20Piedras%20Vivas!5e0!3m2!1sen!2smx!4v1704770352817!5m2!1sen!2smx" title="Central Square, Melbourne" aria-label="Central Square, Melbourne"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
