import React from 'react';
import Resume from './Resume.tsx';
import OurHistory from './OurHistory.tsx';
import Sentence from './Sentence.tsx';
import EventAttractions from './EventAttractions.tsx';
import Attend from './Attend.tsx';
import Pictures from './Pictures.tsx';
import Information from './Information.tsx';
import ConfirmationForm from './ConfirmationForm.tsx';

const Body = () => {
  return (
    <body itemType="https://schema.org/WebPage" itemScope="itemScope" className="home page-template-default page page-id-2 ast-desktop ast-page-builder-template ast-no-sidebar astra-4.5.2 ast-header-custom-item-inside ast-single-post ast-inherit-site-logo-transparent elementor-default elementor-kit-594 elementor-page elementor-page-2">
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PW78FQ8" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
      <div className="hfeed site" id="page">
        <header className="site-header header-main-layout-2 ast-primary-menu-disabled ast-no-menu-items ast-hide-custom-menu-mobile ast-menu-toggle-icon ast-mobile-header-inline" id="masthead" itemType="https://schema.org/WPHeader" itemScope="itemScope" itemID="#masthead"></header>
        <div id="content" className="site-content">
          <div className="ast-container">
            <div id="primary" className="content-area primary">
              <main id="main" className="site-main">
                <article className="post-2 page type-page status-publish ast-article-single" id="post-2" itemType="https://schema.org/CreativeWork" itemScope="itemScope">
                  <header className="entry-header ast-no-thumbnail ast-no-title ast-header-without-markup"></header>
                  <div className="entry-content clear" itemProp="text">
                    <div data-elementor-type="wp-page" data-elementor-id="2" className="elementor elementor-2">
                      <Resume/>

                      <OurHistory/>
                      <EventAttractions/>
                      <Attend/>
                      <Pictures/>
                      <Information/>
                    </div>
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Body;
