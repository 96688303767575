import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import ContactForm from './ContactForm.tsx';

const Attend: React.FC = () => {
  const [state, handleSubmit] = useForm("xvoegzjv");
  if (state.succeeded) {
      return <p>Gracias por contactarnos</p>;
  }

  return (
    <div className="elementor-element elementor-element-2c06333 e-flex e-con-boxed e-con e-parent" data-id="2c06333" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;boxed&quot;}" data-core-v316-plus="true">
      <div className="e-con-inner">
        <div className="elementor-element elementor-element-eb89b58 e-con-full e-flex e-con e-child" data-id="eb89b58" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
          <div className="elementor-element elementor-element-16a3afa elementor-widget elementor-widget-heading" data-id="16a3afa" data-element_type="widget" data-widget_type="heading.default">
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default">¿Asistirás a nuestra boda?</h3>
            </div>
          </div>
          <div className="elementor-element elementor-element-25ba8fb elementor-widget elementor-widget-text-editor" data-id="25ba8fb" data-element_type="widget" data-widget_type="text-editor.default">
            <div className="elementor-widget-container">
              <p>"¡Nos encantaría contar contigo para compartir este día lleno de amor y alegría! Por favor, confírmanos tu asistencia y únete a la celebración de nuestro amor. ¡Esperamos ansiosos tu respuesta!"</p>
            </div>
          </div>
        </div>
        <div className="elementor-element elementor-element-20a16c2 e-con-full e-flex e-con e-child" data-id="20a16c2" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;full&quot;}">
          <div className="elementor-element elementor-element-64bf76c elementor-widget elementor-widget-shortcode" data-id="64bf76c" data-element_type="widget" data-widget_type="shortcode.default">
            <div className="elementor-widget-container">
              <div className="elementor-shortcode">
                <div className="wpforms-container" id="wpforms-512">
                  <ContactForm/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attend;
