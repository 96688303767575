import React from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import './css/AddToCalendarButton.css'; // Importa el archivo CSS
import 'add-to-calendar-button';

const Resume: React.FC = () => {
  return (
    <div className="elementor-element elementor-element-2dbfbda e-flex e-con-boxed e-con e-parent" data-id="2dbfbda" data-element_type="container" data-settings={`{"background_background":"classic","content_width":"boxed"}`} data-core-v316-plus="true">
      <div className="e-con-inner">
        <div className="elementor-element elementor-element-896033c e-con-full e-flex e-con e-child" data-id="896033c" data-element_type="container" data-settings={`{"content_width":"full"}`}>
          <div className="elementor-element elementor-element-4e371dd elementor-widget elementor-widget-heading" data-id="4e371dd" data-element_type="widget" data-widget_type="heading.default">
            <div className="elementor-widget-container">
              <h6 className="elementor-heading-title elementor-size-default">Nos vamos a casar</h6>
            </div>
          </div>
          <div className="elementor-element elementor-element-11729fa elementor-widget elementor-widget-heading" data-id="11729fa" data-element_type="widget" data-widget_type="heading.default">
            <div className="elementor-widget-container">
              <h1 className="elementor-heading-title elementor-size-default">Itz &amp;​ Manu</h1>
            </div>
          </div>
          <div className="elementor-element elementor-element-501b9a1 elementor-widget-divider--view-line_text elementor-widget-divider--element-align-center elementor-widget elementor-widget-divider" data-id="501b9a1" data-element_type="widget" data-widget_type="divider.default">
            <div className="elementor-widget-container">
              <div className="elementor-divider">
                <span className="elementor-divider-separator">
                  <span className="elementor-divider__text elementor-divider__element"> Save The Date </span>
                </span>
              </div>
            </div>
          </div>
          <div className="elementor-element elementor-element-c79ef70 elementor-widget elementor-widget-heading" data-id="c79ef70" data-element_type="widget" data-widget_type="heading.default">
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default">28 de Diciembre de 2024</h3>
            </div>
          </div>
          {/* <div className="elementor-element elementor-element-7cb8a5d elementor-align-center elementor-widget elementor-widget-button" data-id="7cb8a5d" data-element_type="widget" data-widget_type="button.default">
            <div className="elementor-widget-container">
              <div className="elementor-button-wrapper">
                <a className="elementor-button elementor-button-link elementor-size-sm" href="index.html#">
                  <span className="elementor-button-content-wrapper">
                    <div className="add-to-calendar-container">
                      <add-to-calendar-button
                        name="Boda Itzel & Manuel"
                        startDate="2024-12-28"
                        startTime="14:00"
                        endDate="2024-12-29"
                        endTime="20:00"
                        timeZone="America/Mexico_City"
                        location="Hotel Boutique Castillo de Piedras Vivas"
                        description="La boda de Itzel y Manuel en Tepoztlan Morelos"
                        options="'Apple','Google','iCal','Outlook.com','Microsoft Teams'"
                        language="es"
                        buttonStyle="round"
                        lightMode="dark"
                        hideIconButton="true"
                        hideBackground="true"
                      />
                    </div>
                  </span>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Resume;
