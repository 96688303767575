import React from 'react';

const Pictures: React.FC = () => {
  return (
    <div className="elementor-element elementor-element-110b176 e-flex e-con-boxed e-con e-parent" data-id="110b176" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;content_width&quot;:&quot;boxed&quot;}" data-core-v316-plus="true">
      <div className="e-con-inner">
        <div className="elementor-element elementor-element-a583169 e-con-full e-flex e-con e-child" data-id="a583169" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
          <div className="elementor-element elementor-element-f3556e8 gallery-spacing-custom elementor-widget elementor-widget-image-gallery" data-id="f3556e8" data-element_type="widget" data-widget_type="image-gallery.default">
            <div className="elementor-widget-container">
              <div className="elementor-image-gallery">
                <div id="gallery-1" className="gallery galleryid-2 gallery-columns-4 gallery-size-full">
                  {/* Aquí van los elementos de la galería, puedes agregar más figuras según sea necesario */}
                  <figure className="gallery-item">
                    <div className="gallery-icon landscape">
                      <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="f3556e8" data-elementor-lightbox-title="wedding-shoe" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MjQwLCJ1cmwiOiJodHRwczpcL1wvd2Vic2l0ZWRlbW9zLm5ldFwvd2VkZGluZy0wMlwvd3AtY29udGVudFwvdXBsb2Fkc1wvc2l0ZXNcLzQ4XC8yMDE3XC8wOFwvR2FsbGVyeS1pbWc2LWZyZWUtaW1nLmpwZyIsInNsaWRlc2hvdyI6ImYzNTU2ZTgifQ%3D%3D" href="https://weddingmanuitz.s3.amazonaws.com/pic1.jpg">
                        <img loading="lazy" decoding="async" width="350" height="350" src="https://weddingmanuitz.s3.amazonaws.com/pic1.jpg" className="attachment-full size-full" alt="Bridal shoes &amp; flowers bouquet" srcSet="https://weddingmanuitz.s3.amazonaws.com/pic1.jpg 350w, https://weddingmanuitz.s3.amazonaws.com/pic1.jpg 300w, https://weddingmanuitz.s3.amazonaws.com/pic1.jpg 150w" sizes="(max-width: 350px) 100vw, 350px" />
                      </a>
                    </div>
                  </figure>
                  <figure className="gallery-item">
                    <div className="gallery-icon landscape">
                      <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="f3556e8" data-elementor-lightbox-title="wedding-shoe" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MjQwLCJ1cmwiOiJodHRwczpcL1wvd2Vic2l0ZWRlbW9zLm5ldFwvd2VkZGluZy0wMlwvd3AtY29udGVudFwvdXBsb2Fkc1wvc2l0ZXNcLzQ4XC8yMDE3XC8wOFwvR2FsbGVyeS1pbWc2LWZyZWUtaW1nLmpwZyIsInNsaWRlc2hvdyI6ImYzNTU2ZTgifQ%3D%3D" href="https://weddingmanuitz.s3.amazonaws.com/PIC2.jpg">
                        <img loading="lazy" decoding="async" width="350" height="350" src="https://weddingmanuitz.s3.amazonaws.com/PIC2.jpg" className="attachment-full size-full" alt="Bridal shoes &amp; flowers bouquet" srcSet="https://weddingmanuitz.s3.amazonaws.com/PIC2.jpg 350w, https://weddingmanuitz.s3.amazonaws.com/PIC2.jpg 300w, https://weddingmanuitz.s3.amazonaws.com/PIC2.jpg 150w" sizes="(max-width: 350px) 100vw, 350px" />
                      </a>
                    </div>
                  </figure>
                  <figure className="gallery-item">
                    <div className="gallery-icon landscape">
                      <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="f3556e8" data-elementor-lightbox-title="wedding-shoe" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MjQwLCJ1cmwiOiJodHRwczpcL1wvd2Vic2l0ZWRlbW9zLm5ldFwvd2VkZGluZy0wMlwvd3AtY29udGVudFwvdXBsb2Fkc1wvc2l0ZXNcLzQ4XC8yMDE3XC8wOFwvR2FsbGVyeS1pbWc2LWZyZWUtaW1nLmpwZyIsInNsaWRlc2hvdyI6ImYzNTU2ZTgifQ%3D%3D" href="https://weddingmanuitz.s3.amazonaws.com/PIC5.jp">
                        <img loading="lazy" decoding="async" width="350" height="350" src="https://weddingmanuitz.s3.amazonaws.com/PIC5.jpg" className="attachment-full size-full" alt="Bridal shoes &amp; flowers bouquet" srcSet="https://weddingmanuitz.s3.amazonaws.com/PIC5.jpg 350w, https://weddingmanuitz.s3.amazonaws.com/PIC5.jpg 300w, https://weddingmanuitz.s3.amazonaws.com/PIC5.jpg 150w" sizes="(max-width: 350px) 100vw, 350px" />
                      </a>
                    </div>
                  </figure>

                  <figure className="gallery-item">
                    <div className="gallery-icon landscape">
                      <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="f3556e8" data-elementor-lightbox-title="wedding-shoe" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MjQwLCJ1cmwiOiJodHRwczpcL1wvd2Vic2l0ZWRlbW9zLm5ldFwvd2VkZGluZy0wMlwvd3AtY29udGVudFwvdXBsb2Fkc1wvc2l0ZXNcLzQ4XC8yMDE3XC8wOFwvR2FsbGVyeS1pbWc2LWZyZWUtaW1nLmpwZyIsInNsaWRlc2hvdyI6ImYzNTU2ZTgifQ%3D%3D" href="https://weddingmanuitz.s3.amazonaws.com/IMG_7002.jpg">
                        <img loading="lazy" decoding="async" width="350" height="350" src="https://weddingmanuitz.s3.amazonaws.com/IMG_7002.jpg" className="attachment-full size-full" alt="Bridal shoes &amp; flowers bouquet" srcSet="https://weddingmanuitz.s3.amazonaws.com/IMG_7002.jpg 350w, https://weddingmanuitz.s3.amazonaws.com/IMG_7002.jpg 300w, https://weddingmanuitz.s3.amazonaws.com/IMG_7002.jpg 150w" sizes="(max-width: 350px) 100vw, 350px" />
                      </a>
                    </div>
                  </figure>
                  

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pictures;
